import React from "react";
import { LessonScheduleComponent } from "tuteria-frontend-components/src/pages/TutorGroupPages/LessonScheduleComponent";
import { BASE_URL } from "../RequestProvider";

export class LessonSchedulePage extends React.Component {
  state = {
    students: [],
    completed: false,
    loading: false
  };
  getStudents = slug => {
    return fetch(`${BASE_URL}/get-students/${slug}`)
      .then(resp => resp.json())
      .catch(err => console.log(err));
  };
  componentDidMount() {
    let {
      match: { params }
    } = this.props;
    this.getStudents(params.slug).then(data => {
      let { students } = data.data;
      this.node.setState({
        students
      });
    });
  }
  fetchFromServer(url, body) {
    return fetch(`${BASE_URL}${url}`, {
      method: "post",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(body)
    });
  }
  saveLessonInfo = (lessonInfo, callback) => {
    const {
      lessonCount,
      startTime,
      totalPrice,
      ...rest
    } = lessonInfo.request_details;
    this.setState({
      loading: true
    });
    return this.fetchFromServer(
      `/create-and-complete-request/${lessonInfo.slug}`,
      {
        slug: lessonInfo.slug,
        request_details: { ...rest },
        personal_info: lessonInfo.personal_info,
        location: lessonInfo.location
      }
    )
      .then(resp => resp.json())
      .then(data => {
        callback(data.data);
      });
  };
  onSubmit = data => {
    const slug = this.props.match.params.slug;
    this.saveLessonInfo({ slug, ...data }, data => {
      this.setState({
        completed: true,
        loading: false
      });
    });
  };
  render() {
    const { students } = this.state;
    return (
      <React.Fragment>
        <LessonScheduleComponent
          ref={node => (this.node = node)}
          data={{
            hours: 1,
            per_hour: 2500,
            processing_fee: 0,
            transport_fare: 0,
            exam: "ielts",
            discount: 0
          }}
          students={students}
          hourFactors={[]}
          onSubmit={this.onSubmit}
          completed={this.state.completed}
          loading={this.state.loading}
        />
      </React.Fragment>
    );
  }
}

export default LessonSchedulePage;
